@import 'src/styles/index';

.container {
  width: 775px;
  height: 845px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
}

.closeWrap {
  display: flex;
  align-self: end;
}

.closeIcon {
  margin-top: 33px;
  margin-right: 33px;
  margin-bottom: 13px;
  cursor: pointer;
}

.title {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  text-align: center;
  color: #262832;
  margin-bottom: 1rem;
  min-width: 440px;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 440px;
}

.formMobileContent {}

.description {
  @include set-font($size: 1rem, $weight: 400, $family: 'Inter');
  color: #445374;
  text-align: center;
  line-height: 27px;
  margin-bottom: 69px;
}


.hintPassword {
  position: absolute;
  min-width: 110px;
  background-color: #262832;
  top: 0;
  right: -136px;
  border-radius: 10px;
  color: white;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.hintPassword::before {
  content: "";
  position: absolute;
  top: 30%;
  left: -10px;
  margin-top: -10px; /* половина высоты треугольника */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #262832; /* цвет треугольника */
}


.hidePasswordHint {
  opacity: 0;
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;
}

.fadeOut {
  opacity: 1;
  animation: fadeOut 1s ease-out forwards;
}

.heightOut {
  height: 0;
  width: 0;
  overflow: hidden;
  animation: heightOut 1s ease-out forwards;
}

.mobileHintContainer {
  display: none;
}

.changePassDeckBtn {
  margin-top: 126px;
}

@keyframes heightOut {
  from {
    height: 100%;
    width: 100%;
  }
  to {
    height: 0;
    width: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 767px) {
  .container {
    width: auto;
    height: auto;
    background-color: #FFFFFF;
  }

  .closeWrap {
    display: none;
  }

  .title {
    font-size: 16px;
    margin-bottom: 8px;
    min-width: auto;
  }

  .titleMainMobile {
    margin-top: 62px;
    margin-bottom: 24px;
  }

  .description {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 49px;
  }

  .content {
    width: auto;
    padding: 0 32px;
  }

  .formMobileContent {
    width: 100%;
    height: 100%;
  }

  .mobileHintContainer {
    display: block;
    @include set-font($size: 8px, $weight: 400, $family: 'Inter');
    color: #445374;
    height: 126px;
    margin-bottom: 34px;
    margin-top: 16px;
    width: 100%;
  }

  .hintPassword {
    display: none;
  }

  .changePassDeckBtn {
    margin-top: 0;
  }
}
