@import 'src/styles';

.linkItem {
  display: flex;
  max-height: 52px;
  width: 156px;
  padding: 16px;
  background-color: #FAFAFA;
  margin-bottom: .5rem;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;

  &.active {
    background-color: var(--mainBlue); //$mainBlue;
  }

  &:not(.active):hover {
    background-color: rgba(154, 149, 206, 0.2);
  }
}

.navIcon {
  margin-right: 16px;
}

.title {
  @include set-font($size: 1rem, $weight: 400, $family: 'Lexend');
  margin-left: 1rem;
}

.soonImg {
  margin-left: auto;
}

@media (max-width: 767px) {
  .hideMobile {
    display: none;
  }

  .linkItem {
    width: auto;
    border-radius: 15px;
    padding: 12px 24px;
  }

  .title {
    margin-left: 0;
    font-size: 25px;
    font-weight: 500;
  }

  .soonImg {
    margin-left: 12px;
  }
}
