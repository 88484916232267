@import 'src/styles/index';

.container {
  width: 775px;
  height: 927px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
}

.closeWrap {
  display: flex;
  align-self: end;
}

.closeIcon {
  margin-top: 33px;
  margin-right: 33px;
  margin-bottom: 13px;
  cursor: pointer;
}

.title {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  text-align: center;
  color: #262832;
  margin-bottom: 4rem;
}

.content {
  width: 436px;
}

.resultSection {
  flex-grow: 1;
  width: 440px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 10px;
}

.resultTitle {
  @include set-font($size: 1rem, $weight: 500, $family: 'Inter');
  color: #445374;
  margin-left: .5rem;
  margin-bottom: .5rem;
}

.resultList {
  background-color: #FFF;
  border-radius: 10px;
  flex-grow: 1;
  padding: 32px;
  margin-bottom: 36px;
}

.resultItem {
  @include set-font($size: 1rem, $weight: 400, $family: 'Inter');
  color: #445374;
  height: 43px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 12px;

  &:hover {
    background-color: var(--mainBlue);
    color: #FFF;
    cursor: pointer;
  }
}

.summaryTitle {
  @include set-font($size: 0.75rem, $weight: 500, $family: 'Inter');
  color: #445374;
  line-height: 14px;
}

.summaryData {
  @include set-font($size: 1.5rem, $weight: 600, $family: 'Inter');
  color: #445374;
  line-height: 29px;
  margin-top: 16px;
}

.completedText {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  max-width: 423px;
  text-align: center;
}

.completedText2 {
  @include set-font($size: 1rem, $weight: 400, $family: 'Inter');
  color: #445374;
  margin-top: 24px;
}

.inputWrap {
  width: 440px;
}

.backBtn {
  background-color: #FAFAFA;
  margin-top: 12px;
  color: black;

  &:hover {
    background-color: #FFFFFF;
  }
}

.submitBtn {
  max-width: 440px;
  margin-bottom: 110px;
}

@media (max-width: 767px) {
  .container {
    width: auto;
    height: 100%;
    min-height: 70vh;
    max-width: 100vw;
    padding: 0 15px;
    background-color: #FFFFFF;
  }

  .title {
    font-size: 16px;
    margin-top: 64px;
    margin-bottom: 29px;
  }

  .inputWrap {
    width: 100%;
  }

  .closeWrap {
    display: none;
  }

  .resultSection {
    width: 100%;
  }

  .backBtn {
    background-color: #FFFFFF;
    margin-top: 12px;
    color: black;

    &:hover {
      background-color: #FAFAFA;
    }
  }

  .submitBtn {
    margin-bottom: 0;
  }
}
