@import 'src/styles';

.cardWrap {
  background-color: #FFFFFF;
  padding: 24px;
  border-radius: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  box-shadow: 0 30px 30px #A3A3A30D;
}

.cardTitle {
  @include set-font($size: 1rem, $weight: 500, $family: 'Lexend');
  color: var(--mainBlue);
  margin-bottom: 10px;
}

.cardAmount {
  @include set-font($size: 1.5rem, $weight: 600, $family: 'Lexend');
  color: #262832;
  margin-bottom: 12px;
}

.cardChangeRate {
  @include set-font($size: 1rem, $weight: 500, $family: 'Lexend');
  color: #2B9A18;
  display: flex;
  min-height: 70px;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
}

.cardChangeRateWrap {
  min-height: 31px;
  display: flex;
  align-items: center;
}

.arrow {
  cursor: pointer;
  margin-left: auto;
}

.cardIcon {
  width: 62px;
  height: 62px;
  margin-right: 18px;
}

.cardTitleAmount {
  min-width: 134px;
}

.cardChangeRateMinus {
  color: #FF0000;
}

@media (max-width: 767px) {
  .arrow {
    //display: none;
  }

  .arrowIcon {
    width: 20px;
  }

  .cardIcon {
    display: none;
  }

  .cardTitleAmount {
    min-width: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
  }

  .cardAmount {
    order: 2;
    font-size: 18px;
    text-align: center;
    margin-bottom: 5px;
  }

  .cardWrap {
    flex-direction: column;
    padding: 23px;
    box-shadow: 0 0 0 #A3A3A30D;
  }

  .cardTitle {
    font-size: 12px;
    text-align: center;
    margin-bottom: 5px;
    color: #445374;
  }

  .cardChangeRateWrap {
    font-size: 8px;
    min-height: 0;
    padding: 5px 10px;
    background-color: #D5EBD1;
    border-radius: 20px;
  }

  .cardChangeRate {
    min-height: 0;
    //background-color: #D5EBD1;
    border-radius: 20px;
    width: 100%;
    flex-direction: row;
  }

  .cardChangeRateMinus {
    background-color: #FFCCCC;
    color: #FF0000;
    border-radius: 20px;
  }
}
