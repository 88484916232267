@mixin flex($alignItems, $justifyContent, $flexDirection: row, $wrap: wrap) {
  display: flex;
  align-items: $alignItems;
  justify-content: $justifyContent;
  flex-direction: $flexDirection;
  flex-wrap: $wrap;
}

@mixin set-font($family: null, $size: null, $lineHeight: null, $weight: null, $mapKey: null) {
  @if ($family) {
    font-family: $family, sans-serif;
  }

  @if ($mapKey) {
    font-size: map-get($font-size-map, $mapKey);
    line-height: map-get($font-height-map, $mapKey);
    font-weight: map-get($font-weight-map, $mapKey);
  }

  @if ($size) {
    font-size: $size;
  }

  @if ($lineHeight) {
    line-height: $lineHeight;
  }

  @if ($weight) {
    font-weight: $weight;
  }
}
