@import 'src/styles';

.transactionsWrap {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.item {
  display: flex;
  margin: 12px 0;
}

.icon {
  margin-right: 36px;
}

.itemTitle {
  @include set-font($size: 1rem, $weight: 600, $family: 'Inter');
  color: #445374;
  line-height: 20px;
  margin-bottom: 8px;
}

.itemDate {
  @include set-font($size: 0.75rem, $weight: 400, $family: 'Inter');
  color: #99A1B2;
  line-height: 15px;
}

.itemAmount {
  @include set-font($size: 1rem, $weight: 600, $family: 'Inter');
  color: #445374;
  line-height: 20px;
  //margin-bottom: 8px;
  margin-left: auto;
}

.transactionsBlock {
  //background-color: rgba(170, 157, 228, 0.1);
  flex-grow: 1;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  //align-items: center;
  @include set-font($size: 12px, $weight: 400, $family: 'Inter');
  flex-direction: column;
  padding: 12px;
}

.freeHistoryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 350px;
  margin: 150px auto;
  padding: 24px;
}

.freeHistory {
  @include set-font($size: 24px, $weight: 700, $family: 'Lexend');
  margin-top: 30px;
}

.freeHistoryDescription {
  @include set-font($size: 14px, $weight: 400, $family: 'Inter');
  color: rgba(68, 83, 116, 1);
  line-height: 24px;
  margin-top: 16px;
  max-width: 453px;
  margin-bottom: 57px;
}

@media (max-width: 767px) {
  .transactionsWrap {
    min-width: 100%;
    max-width: 100%;
  }

  .transactionsListWrap {
    padding: 16px;
  }

  .transactionsContent {
    padding: 0 12px;
  }

  .title {
    @include set-font($size: 1rem, $weight: 700, $family: 'Lexend');
    text-align: center;
    color: #262832;
    //margin-top: 36px;
    margin-bottom: 20px;
  }

  .transactionsBlock {
    background-color: #FFFFFF;
  }

  .freeHistoryContainer {
    width: auto;
  }

  .icon {
    margin-right: 12px;
    height: 41px;
  }

  .item {
    margin: 8px 0;
    padding: 8px;

    &:hover {
      background-color: #F5F4FA;
      border-radius: 10px;
    }
  }

  .itemTitle {
    font-size: 14px;
    line-height: 17px;
  }

  .itemAmount {
    font-size: 14px;
    text-align: right;
    line-height: 17px;
  }

  .itemDate {
    font-size: 10px;
  }

  .freeHistoryContainer {
    margin-top: 20px;
  }

  .freeHistory {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .freeHistoryDescription {
    max-width: 298px;
  }
}
