@import 'src/styles';

.bankingWrap {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.mainContent {
  display: flex;
  margin-left: 24px;
}

.title {
  @include set-font($size: 2.125rem, $weight: 700, $family: 'Lexend');
  padding-top: 54px;
  padding-bottom: 36px;
  color: #262832;
  margin-left: 24px;
}

.balanceWrap {
  //width: 504px;
  min-width: 644px;
  max-width: 700px;
  margin-right: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.fiatBalanceCard {
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0 30px 30px #A3A3A30D;
  padding: 24px;
}

.balanceTitle {
  @include set-font($size: 1rem, $weight: 500, $family: 'Lexend');
  color: #445374;
  margin-bottom: 12px;
}

.balance {
  @include set-font($size: 1.5rem, $weight: 600, $family: 'Lexend');
  color: #262832;
  margin-bottom: 30px;
}

.divider {
  border-bottom: 1px solid rgba(38, 40, 50, 0.1);
  //margin: 24px 0 32px;
  width: 100%;
}

.cardWrap {
  height: 100%;
}

.transferWrap {
  //max-width: 1032px;
  //min-width: 530px;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0 30px 30px #A3A3A30D;
  margin-right: 24px;
  flex-grow: 2;
}

.item {
  display: flex;
  margin: 24px 0;
  padding: 0 24px;
}

.icon {
  margin-right: 36px;
}

.itemDate {
  @include set-font($size: 0.75rem, $weight: 400, $family: 'Inter');
  color: #99A1B2;
  line-height: 15px;
}

.itemAmount {
  @include set-font($size: 1rem, $weight: 600, $family: 'Inter');
  color: #445374;
  line-height: 20px;
  margin-left: auto;
}

.btnTitle {
  @include set-font($size: 14px, $weight: 600, $family: 'Inter');
}

.freeHistoryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 350px;
  margin: 150px auto 0;
  padding: 24px;
}

.freeHistory {
  @include set-font($size: 24px, $weight: 700, $family: 'Lexend');
  margin-top: 30px;
}

.freeHistorySubmitted {
  margin-top: 36px;
}

.freeHistoryDescription {
  @include set-font($size: 14px, $weight: 400, $family: 'Inter');
  color: rgba(68, 83, 116, 1);
  line-height: 24px;
  margin-top: 16px;
  max-width: 453px;
  margin-bottom: 57px;
}

.freeHistoryDescriptionThree {
  text-align: center;
  margin: 90px auto 57px;
  max-width: 496px;
}

.freeHistoryDescriptionFive {
  text-align: center;
  margin-top: 90px;
}

.freeHistoryDescriptionSubmitted {
  margin-top: 9px;
}

.stepContentContainer {
  width: 440px;
  margin: 0 auto;
}

.enterAmount {
  @include set-font($size: 1rem, $weight: 500, $family: 'Inter');
  color: #445374;
  margin-bottom: 12px;
  margin-left: .5rem;
}

.enterAmountTwo {
  margin-top: 64px;
}

.countryDropDownWrap {
  position: relative;
  margin-bottom: 48px;
  width: 434px;
}

.inputBlock {
  @include set-font($size: 18px, $weight: 500, $family: 'Inter');
  color: #445374;
  display: flex;
  border-radius: 10px;
  //justify-content: space-between;
  align-items: center;
  background-color: rgba(61, 92, 245, 0.1);
  height: 62px;
}

.input {
  @include set-font($size: 1.125rem, $weight: 500, $family: 'Inter');
  border: 0;
  width: 30px;
  background-color: rgba(154, 149, 206, 0);
  color: #445374;
  margin-left: 12px;
}

.fiatBalanceAmount {
  @include set-font($size: 1rem, $weight: 600, $family: 'Inter');
  color: #445374;
  margin-left: .5rem;
  margin-top: 1rem;
  margin-bottom: 8px;
}

// STEPS
.mainStepContainer {
  width: 100%;
  max-width: 459px;
  padding: 0 15px;
  margin: 0 auto 65px;
}

.stepContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    background: var(--mainBlue);
    opacity: 0.2;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
}

.stepWrapper {
  position: relative;
  //z-index: 1;
}

.stepStyle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #ffffff;
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stepCount {
  font-size: 19px;
  color: #f3e7f3;
}

.stepsLabelContainer {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stepLabel {
  @include set-font($size: 12px, $weight: 400, $family: 'Inter');
  color: #445374;
  //opacity: .4;
}

.stepLabelActive {
  opacity: 1;
  color: #445374;
  @include set-font($size: 12px, $weight: 700, $family: 'Inter');
}

.checkMark {
  font-size: 26px;
  font-weight: 600;
  color: #4a154b;
  background-color: red;
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
}

.activeLine {
  position: absolute;
  background-color: var(--mainBlue);
  height: 4px;
  top: 42%;
  transition: 0.4s ease;
  margin: 0 5px;
}

.summaryBlock {
  margin-left: 5px;
  margin-bottom: 12px;
}

.summaryTitle {
  @include set-font($size: 16px, $weight: 400, $family: 'Inter');
  color: #445374;
  opacity: .8;
}

.summaryData {
  @include set-font($size: 18px, $weight: 700, $family: 'Lexend');
  color: #262832;
  margin-top: 4px;
}

.summaryConfirmed {
  @include set-font($size: 18px, $weight: 400, $family: 'Lexend');
  color: #2B9A18;
}

/* Customize Check Box + the label (the container) */
.container {
  @include flex(center, start, row);
  @include set-font($mapKey: 0, $weight: 500, $family: 'Inter');
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 1rem;
  color: #445374;
  margin-left: 50px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 6px;
  background-color: rgba(154, 149, 206, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  width: 1rem;
  height: 1rem;
  border-radius: 4px;
  background-color: $link-color;
}

.fiatTransactionWeb {
  display: block;
}

.fiatTransactionMobile {
  display: none;
}

.cardImg {
  height: 282px;
  width: 453px;
}

.cardImgTwo {
  height: 191px;
  width: 302px;
  margin-top: 24px;
}

.orderBtn {
  max-width: 440px;
  margin-bottom: 87px;
}

.cardTitle {
  margin: 24px;
}

.cardTitleSubmitted {
  height: 68px;
}

.cardImageWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 148px;
}

.stepThreeBtnWrap {
  display: flex;
  margin-top: 48px;
}

.stepFourBtnWrap {
  display: flex;
  margin-top: 48px;
}

.bankName {
  padding-left: 8px;
}

@media (max-width: 767px) {
  .bankingWrap {
    min-width: 100%;
    max-width: 100%;
  }

  .mainContent {
    //margin-left: 0;
    flex-direction: column;
    margin: 0 15px;
  }

  .balanceWrap {
    margin-right: 0;
    min-width: auto;
  }

  .balanceTitle {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .balance {
    font-size: 18px;
    margin-bottom: 24px;
  }

  .btnTitle {
    font-size: 10px;
  }

  .fiatBtn {
    min-height: 40px;
  }

  .fiatBalanceCard {
    padding: 17px;
  }

  .fiatTransactionWeb {
    display: none;
  }

  .fiatTransactionMobile {
    display: block;
  }

  .transferWrap {
    margin-right: 0;
    margin-top: 12px;
  }

  .cardImg {
    height: 153px;
    width: 246px;
  }

  .freeHistory {
    font-size: 12px;
    margin-top: 28px;
  }

  .freeHistoryDescription {
    font-size: 10px;
    line-height: 13px;
    max-width: 270px;
    margin-bottom: 19px;
  }

  .orderBtn {
    min-height: 30px;
    font-size: 10px;
    max-width: 239px;
    border-radius: 5px;
  }

  .cardTitle {
    margin: 17px;
  }

  .cardImageWrap {
    margin-top: 94px;
  }

  .cardWrap {
    min-height: auto; // 550px;
    margin-bottom: 17px;
  }

  .cardWrapSubmitted {
    height: 513px;
  }

  .cardTitleSubmitted {
    height: 33px;
  }

  .freeHistorySubmitted {
    margin-top: 17px;
  }

  .freeHistoryDescriptionSubmitted {
    margin-top: 0;
  }

  .freeHistoryContainer {
    width: auto;
  }


  .stepLabel {
    font-size: 9px;
  }

  .stepLabelActive {
    font-size: 9px;
  }

  .mainStepContainer {
    max-width: none;
    width: auto;
    margin: auto;
  }

  .stepContentContainer {
    width: auto;
    padding: 0 17px;
  }

  .freeHistoryDescription {
    max-width: 298px;
  }

  .enterAmount {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .inputBlock {
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
  }

  .input {
    font-size: 14px;
    width: 20px;
  }

  .fiatBalanceAmount {
    font-size: 12px;
  }

  .btnTitle {
    font-size: 14px;
  }

  .cardImgTwo {
    margin-top: 63px;
  }

  .enterAmountTwo {
    margin-top: 40px;
  }

  .countryDropDownWrap {
    margin-bottom: 24px;
    width: auto;
  }

  .freeHistoryDescriptionThree {
    text-align: center;
    margin: 56px auto 38px;
  }

  .stepThreeBtnWrap {
    margin-top: 35px;
  }

  .stepFourBtnWrap {
    margin-top: 100px;
  }

  .freeHistoryDescriptionFive {
    text-align: center;
    margin: 55px auto;
  }

  .container {
    margin-left: 33px;
    font-size: 12px;
  }

  .summaryTitle {
    font-size: 10px;
  }

  .summaryData {
    font-size: 12px;
  }

  .summaryConfirmed {
    font-size: 12px;
  }

  .activeLine {
    margin: 0;
    height: 3px;
  }

  .stepStyle {
    width: 10px;
    height: 10px;
  }
}
