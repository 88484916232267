@import 'src/styles';

.earnWrap {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 1582px;
  max-width: 2700px;
}

.tableContainer {
  flex: 1 1 auto;
  overflow: auto;
  padding: 24px;
  background-color: rgba(163, 163, 163, 0.05);
  border-radius: 10px;
  margin-left: 24px;
  margin-right: 24px;
  background-color: #FFFFFF;
}

.tableTitleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom-width: 1px;
  border-color: #F3F3F4;
  border-bottom-style: solid;
}

.tableTitle {
  @include set-font($size: 1rem, $weight: 500, $family: 'Lexend');
  color: #445374;
  margin-bottom: 12px;
}

.newEarn {
  width: 156px;
  min-height: 36px;
  margin-top: 0;
  font-size: 14px;
}

.earnInfoBlock {
  display: flex;
  flex-direction: row;
  height: 79px;
  padding: 24px 0;
  border-bottom-width: 1px;
  border-color: #F3F3F4;
  border-bottom-style: solid;
}

.earnInfoItem {
  background-color: var(--mainBlue);
  min-width: 191px;
  padding: 15px 18px;
  border-radius: 10px;
  margin-right: 12px;
  color: #FFFFFF;
  @include set-font($size: 12px, $weight: 500, $family: 'Inter');
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.earnInfoData {
  display: flex;
  align-items: center;
  @include set-font($size: 14px, $weight: 600, $family: 'Inter');
}

.earnInfoItemTitle {
  opacity: .8;
}

.tierInfo {
  display: flex;
  justify-content: space-between;
}

.dot {
  background-color: #fff;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  margin-left: 4px;
}

.dot2 {
  background-color: #fff;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  margin: 0 12px;
}

.earnLastInfoItem {
  //background-color: red;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.reachNextTier {
  @include set-font($size: 12px, $weight: 500, $family: 'Inter');
  color: #99A1B2;
}

.lockInputWrap {
  display: flex;
  justify-content: space-between;
}

.lockInputBtn {
  width: 170px;
  min-height: 52px;
  margin-top: 0;
  @include set-font($size: 16px, $weight: 500, $family: 'Lexend');
}

.lockInput {
  @include set-font($mapKey: 0, $weight: 400, $family: 'Inter');
  background-color: rgba(154, 149, 206, 0.1);
  padding: 0 1rem;
  border-radius: .625rem;
  border: 0;
  flex-grow: 1;
  outline: none;
  color: $text-color-2;
  margin-right: 8px;
  max-height: 52px;
  min-width: 251px;

  &::placeholder {
    opacity: 0.5;
    font-size: 14px;
  }

  &:focus-visible {
    border: 1px solid $link-color;
  }
}

.table {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #445374;
  @include set-font($size: 0.875rem, $weight: 600, $family: 'Inter');

  &:hover {
    background-color: rgba(154, 149, 206, 0.08);
    border-radius: 5px;
  }
}

.header {
  @include set-font($size: 0.75rem, $weight: 500, $family: 'Inter');
  color: #99A1B2;

  &:hover {
    background-color: #fff;
    cursor: auto;
  }
}

.cell {
  flex: 1;
  flex-basis: 0;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.cell1 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 220px;
}

.cell2 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 350px;
}

.cell3 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 150px;
}

.cell4 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100px;
}

.cell5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50px;
}

.assetName {
  margin-left: 21px;
  align-self: center;
  display: flex;
}

.progressBar {
  width: 100%;
  height: 4px;
  border-radius: 10px;
  background-color: var(--mainBlue);
  margin: 0 5px;
  display: flex;
  justify-content: end;
}

.progressBarFill {
  height: 100%;
  //border-radius: 10px;
  background-color: #F5F4FA;
}

.actionBtn {
  position: relative;
  width: 337px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ECECED;
  border-radius: 7px;
  margin-right: 8px;
  cursor: pointer;
  background-color: #FFFFFF;

  &:hover {
    background-color: #F8F8F8;
  }
}

.clearStakeWrap {
  margin-top: 144px;
  display: flex;
  justify-content: center;
}

.clearStake {
  max-width: 496px;
  text-align: center;
}

.clearStakeWrapTitle {
  @include set-font($size: 30px, $weight: 700, $family: 'Lexend');
  margin-bottom: 16px;
}

.clearStakeWrapDescription {
  @include set-font($size: 16px, $weight: 400, $family: 'Inter');
  line-height: 27px;
}

.rowMobile {
  display: none;
}

@media (max-width: 767px) {
  .earnWrap {
    min-width: 100%;
    max-width: 100%;
  }

  .tableContainer {
    padding: 0;
    background-color: rgba(163, 163, 163, 0.05);
    border-radius: 0;
    margin: 0;
    background-color: #FAFAFA;
  }

  .tableTitleRow {
    margin: 24px 30px;
    border-bottom-style: hidden;
  }

  .tableTitle {
    font-size: 12px;
    //color: #445374;
    margin-bottom: 0;
  }

  .newEarn {
    width: 117px;
    background-color: #262832;
    min-height: 27px;
    border-radius: 8px;
    font-size: 11px;
  }

  .earnInfoBlock {
    padding: 0;
    flex-wrap: wrap;
    border-bottom-style: none;
    height: auto;
    justify-content: space-between;
  }

  .earnInfoItem {
    min-width: auto;
    margin-right: 0;
    border-radius: 10px;
    background: rgba(154, 149, 206, 0.10);
    padding: 0;
  }

  .earnInfoItemOrder1 {
    order: 1;
    width: 40%;
    margin-left: 32px;
    margin-bottom: 12px;
  }

  .earnInfoItemOrder2 {
    order: 2;
    width: 40%;
    margin-right: 28px;
    margin-bottom: 12px;
  }

  .earnInfoItemOrder3 {
    order: 3;
    width: 100%;
    margin-right: 28px;
    margin-left: 32px;
    margin-bottom: 24px;
  }

  .earnLastInfoItem {
    order: 4;
    margin-right: 28px;
    margin-left: 32px;
    width: 100%;
  }

  .earnInfoItemTitle {
    font-size: 10px;
    opacity: 0.5;
    color: rgba(68, 83, 116, 0.50);
    margin-top: 11px;
    margin-left: 13px;
    margin-bottom: 8px;
  }

  .earnInfoData {
    color: #262832;
    font-size: 12px;
    margin-left: 13px;
    margin-bottom: 12px;
  }

  .lockInput {
    min-width: 10px;
    border-radius: 5px;
    width: 10px;
    font-size: 10px;

    &::placeholder {
      opacity: 0.5;
      font-size: 10px;
    }
  }

  .lockInputBtn {
    width: auto;
    font-size: 12px;
    padding: 0 12px;
    min-height: 39px;
  }

  .lockInputWrap {
    height: 39px;
  }

  .dot {
    display: none;
  }

  .reachNextTier {
    margin-top: 9px;
    font-size: 8px;
    margin-left: 4px;
  }

  .table {
    background-color: #FFFFFF;
    margin: 0 15px 20px;
    border-radius: 10px;
    padding: 10px 8px;
  }

  .clearStake {
    margin: 76px 30px;
  }

  .clearStakeWrap {
    margin-top: 0;
  }

  .clearStakeWrapTitle {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .clearStakeWrapDescription {
    font-size: 10px;
    line-height: 17px;
  }

  .header {
    display: none;
  }

  .row {
    display: none;
  }

  .rowMobile {
    display: flex;
    font-size: 16px;
    padding: 8px;
    justify-content: space-between;
    color: #262832;
    @include set-font($size: 16px, $weight: 600, $family: 'Inter');
    overflow: hidden;
  }

  .rowMobile:hover {
    background-color: rgba(170, 157, 228, 0.10);
    border-radius: 10px;
  }

  .assetName {
    align-self: start;
    margin-left: 5px;
  }

  .progressBar {
    width: auto;
  }

  .dot2 {
    background-color: #262832;
  }
}
