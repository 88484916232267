@import 'src/styles';

.title {
  @include set-font($size: 2.125rem, $weight: 700, $family: 'Lexend');
  padding-top: 54px;
  padding-bottom: 36px;
  color: #262832;
}

.headerTitleWrap {
  display: flex;
  justify-content: space-between;
  margin-left: 24px;
}

.titleWrap {
  display: flex;
  position: relative;
}

.unstoppable {
  color: #FFF;
  height: 32px;
  line-height: 24px;
  @include set-font($size: 0.875rem, $weight: 400, $family: 'Inter');
  margin-top: 60px;
  display: flex;
  align-items: center;
  margin-left: 26px;
  cursor: pointer;
}

.unstoppableName {
  display: flex;
  background-color: var(--mainBlue);
  border-radius: 100px;
  padding: 0 12px;
  height: 32px;
  align-items: center;
}

.copyWhiteIcon {
  margin-left: 12px;
  cursor: pointer;
}

.copiedUd {
  //margin-top: 24px;
  //margin-left: 20px;
}

.transactionsBtn {
  display: flex;
  align-items: center;
  margin-left: 24px;
  height: 50px;
  margin-top: 40px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #FFF;
  padding: 0 16px;
}

.navMenu {
  display: none;
  cursor: pointer;
}

.backBtnWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  @include set-font($size: 12px, $weight: 400, $family: 'Inter');
  color: #8D8A95;
  cursor: pointer;
  margin-top: 40px;
}

.backBtn {
  display: flex;
  background-color: rgba(154, 149, 206, 0.1);
  padding: 10px 12px;
  border-radius: 20px;
}

.assetName {
  @include set-font($size: 12px, $weight: 600, $family: 'Inter');
  color: #445374;
}

.mobClearBlock {
  display: none;
}

@media (max-width: 767px) {
  .title {
    font-size: 24px;
    margin-right: 5px;
    padding-bottom: 24px;
    padding-top: 64px;
    flex-grow: 0;
  }

  .headerTitleWrap {
    margin: 0 15px;
  }

  .titleWrap {
    flex-grow: 1;
    justify-content: space-between;
  }

  .unstoppable {
    height: 24px;
    font-size: 10px;
    margin-top: 65px;
    margin-left: 0;
    flex-grow: 1;
    justify-content: center;
  }

  .unstoppableName {
    display: flex;
    height: 24px;
  }

  .copyWhiteIcon {
    margin-top: 5px;
  }

  .transactionsBtn {
    display: none;
  }

  .navMenu {
    display: block;
    margin-top: 67px;
  }

  .copiedUd {
    width: 100%;
    margin: 0;
  }

  .mobClearBlock {
    display: block;
    width: 10px;
  }
}
