@import 'src/styles';

.individualTokenWrap {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 1608px;
}

.chartActionBlock {
  display: flex;
  flex: 1;
}

.chartBlock {
  width: 875px;
  background-color: #FFFFFF;
  border-radius: 10px;
  margin-left: 25px;
  flex-grow: 2;
}

.chartBlockWrap {
  padding: 36px;
}

.mobileBtnBlock {
  display: none;
}

.actionsWrap {
  background-color: #FFFFFF;
  display: flex;
  border-radius: 10px;
  width: 636px;
  color: #262832;
  flex-grow: 1;
}

.assetInfoWrap {
  display: flex;
  justify-content: space-between;
}

.assetName {
  @include set-font($size: 24px, $weight: 600, $family: 'Lexend');
  color: #262832;
}

.assetChangeWrap {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.assetId {
  @include set-font($size: 20px, $weight: 400, $family: 'Lexend');
  color: #99A1B2;
}

.assetChange {
  @include set-font($size: 20px, $weight: 400, $family: 'Lexend');
}

.timeLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 33px;
  background-color: rgba(154, 149, 206, 0.1);
  color: #445374;
  @include set-font($size: 12px, $weight: 600, $family: 'Inter');
  border-radius: 7px;
}

.timeLineItem {
  height: 29px;
  width: 129px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(154, 149, 206, 0.08);
    border-radius: 7px;
  }
}

.timeLineItemActive {
  background-color: var(--mainBlue); //#262832;
  color: #FFF;
  &:hover {
    background-color: var(--mainBlue); //#262832;
    color: #FFF;
  }
}

.graphText {
  display: flex;
  justify-content: center;
  width: 519px;
  margin: 49px auto 0;
  @include set-font($size: 14px, $weight: 400, $family: 'Lexend');
  text-align: center;
  color: #445374;
}

.totalBalanceTitle {
  @include set-font($size: 16px, $weight: 400, $family: 'Lexend');
}

.totalBalanceAmount {
  @include set-font($size: 36px, $weight: 600, $family: 'Lexend');
  margin-top: 20px;
  margin-bottom: 4px;
}

.divider {
  border-bottom: 1px solid rgba(38, 40, 50, 0.1);
  margin: 34px 0;
}

.changeRatesBlock {
  display: flex;
  justify-content: space-between;
}

.transactionsTitle {
  margin-top: 64px;
  margin-bottom: 29px;
  @include set-font($size: 18px, $weight: 400, $family: 'Lexend');
}

.transactionsBlock {
  background-color: #FFFFFF;
  border-radius: 10px;
}

.headerTitle {
  height: 129px;
  margin-right: 25px;
  margin-left: 25px;
}

.headerLine {
  min-height: 28px;
}

.graphIcon {
  display: flex;
  align-items: center;
}

.mobBackTitle {
  display: none;
}

.priceChartWrap {
  height: 515px;
  margin-top: 110px;
  margin-bottom: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.latestTransaction {
  display: none;
}

@media (max-width: 767px) {

  .transactionsTitle {
    margin-left: 8px;
    color: #445374;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  .headerTitle {
    display: none;
  }

  .headerLine {
    display: none;
  }

  .individualTokenWrap {
    min-width: 100%;
    max-width: 100%;
  }

  .mobBackTitle {
    padding-top: 64px;
    margin: 0 16px 24px;
    display: flex;
    justify-content: space-between;
  }

  .mobBack {
    background-color: #FFFFFF;
    display: flex;
    //padding: 4px;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
  }

  .mobTitle {
    display: flex;
    align-items: center;
    @include set-font($size: 24px, $weight: 600, $family: 'Lexend');
  }

  .graphIcon {
    display: none;
  }

  .chartBlockWrap {
    padding: 0 0 16px;
    //padding-bottom: 16px;
  }

  .assetInfoWrap {
    padding: 24px;
  }

  .chartActionBlock {
    display: block;
    padding: 0 16px;
  }

  .chartBlock {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
    margin: 0;
    flex-grow: 0;
  }

  .graphText {
    display: none;
  }

  .priceChartWrap {
    height: 223px;
    margin-top: 0;
    margin-bottom: 24px;
  }

  .timeLine {
    margin: 0 24px 25px;
  }

  .mobileBtnBlock {
    display: block;
    text-align: center;
    margin: 0 16px;
  }

  .totalBalanceTitle {
    font-size: 12px;
  }

  .totalBalanceAmount {
    font-size: 24px;
    margin-top: 16px;
    margin-bottom: 7px;
  }

  .actionsWrap {
    display: none;
  }

  .latestTransaction {
    display: block;
  }

  .assetName {
    font-size: 18px;
  }

  .assetChange {
    font-size: 12px;
    margin-top: 5px;
  }

  .assetChangeWrap {
    align-items: flex-start;
  }

  .btnTradeMobile {
    height: 55px;
  }
}
