body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --mainBlue: #9A95CE;
  --iconBackground: #445374;
  --btnHover: #918CC1;
  --inputBackGround: #ECECEC;
  --disableBtn: #EBEAF5;
  --copyBtn: #F5F4FA;
  --amountInputBackground: #F0F0F6;
  --dropDownListBg: #F8F8F8;
}

[data-theme='fideum'] {
  --mainBlue: #3D5CF5;
  --iconBackground: #3D5CF5;
  --btnHover: #2E4CDF;
  --inputBackGround: #ECECEC;
  --disableBtn: #D8DEFD;
  --copyBtn: #ECEFFE;
  --amountInputBackground: #E7EAF9;
  --dropDownListBg: #F6F7FF;
}

[data-theme='kaizen'] {
  --mainBlue: #003EF5; /* #00AEEF */
  --iconBackground: #003EF5;
  --btnHover: #002FB8;
  --inputBackGround: #ECECEC;
  --disableBtn: #CCEFFC;
  --copyBtn: #F8F8F8;
  --amountInputBackground: #F3F3F3;
  --dropDownListBg: #F6F7FF;
}
