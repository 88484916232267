@import 'src/styles';
//@import '../index.css';

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Rubik', sans-serif;
  max-width: 100vw;
  overflow-x: hidden;
  margin: 0;
  color: $text-color;
  background-color: #FAFAFA;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  white-space: pre-line;
}

main,
.app {
  display: flex;
  flex-direction: column;
  flex: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Bebas Neue', cursive;
  color: $text-color;
  font-weight: 500;
  line-height: 1;
}

h1 {
  font-size: 10.4rem;
}

h2 {
  font-size: 7rem;
}

h3 {
  font-size: 6.6rem;
}

ol,
ul {
  padding: 0;
  text-align: left;
  margin: 0;
  font-size: 1.8rem;
  color: $text-color;
}

p {
  font-size: 1rem;
  color: $text-color;
  line-height: 1.2;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

ul li,
ol li {
  font-size: 1.6rem;
  color: $text-color;
  line-height: 1.2;
}

a {
  text-decoration: none;
  color: $link-color;
  transition: all 0.3s;

  &:hover {
    text-decoration: underline;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.flex-start {
  @include flex(flex-start, flex-start, column);
}

.row-start {
  @include flex(stretch, flex-start, row);
}

.row-end {
  @include flex(stretch, flex-end, row);
}

.align-center {
  @include flex(center, flex-start, row);
}

.column-center {
  @include flex(center, center, column);
}

.justify-row-center {
  @include flex(center, center, row, nowrap);
}

.justify-row-stretch {
  @include flex(stretch, center, row);
}

.mb-16 {
  margin-bottom: 1.6rem;
}

.mt-4 {
  margin-top: 0.4rem;
}

.mt-20 {
  margin-top: 2rem;
}

.mt-40 {
  margin-top: 4rem;
}

.ml-10 {
  margin-left: 1rem;
}

.ml-20 {
  margin-left: 2rem;
}

.py-20 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.input-item-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  .input-label {
    @include set-font($mapKey: 0, $weight: 500, $family: 'Inter');
    margin-left: .5rem;
    color: $text-color-2;
  }

  .text-error {
    color: red;
  }

  ::placeholder {
    color: $text-color-2;
    opacity: .5;
  }

  .input-form {
    @include set-font($mapKey: 0, $weight: 400, $family: 'Inter');
    background-color: var(--amountInputBackground);
    padding: 1rem;
    border-radius: .625rem;
    border: 0;
    margin-top: .3rem;
    flex-grow: 1;
    outline: none;
    color: $text-color-2;

    &:focus-visible {
      border: 1px solid $link-color;
    }
    &:focus-within {
      outline: 0;
      border: 1px solid $link-color;
      //border-radius: .625rem;
    }
  }

  .icon-eye {
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: calc(50% + 0.15rem);
    right: 1.5rem;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .input-password-wrap {
    position: relative;
    width: 100%;
    display: flex;
  }

  .form-link {
    //@include set-font($mapKey: 0, $weight: 500, $family: 'Inter');
    color: $link-color;
    margin-top: .3rem;
    text-decoration: none;
    transition: all 0.3s;

    &_inline {
      margin-left: 0.8rem;
    }

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.btn {
  @include set-font($mapKey: 2, $weight: 500, $family: 'Lexend');
  margin-top: .5rem;
  min-height: 3.375rem;
  width: 100%;
  border: 0;
  transition: all 0.3s;
  border-radius: .625rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &-primary {
    background: var(--mainBlue); // $link-color;
    color: $white;
  }

  &:hover {
    background: var(--btnHover)//$btn-hover;
  }
}

.spinner-border {
  position: relative;
}

.spinner-border:after {
  content: "";
  position: absolute;
  top: 50%;
  //left: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 2px solid #DADDE3;
  border-top-color: transparent;
  animation: spin 1s infinite;
  z-index: 1;
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.btn-block {
  background-color: rgba(154, 149, 206, 0.5);
}

.text-under-button {
  margin-top: 1rem;
}

.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    //opacity: 0;
    transform: translate(100%, 0);
  }
  to {
    //opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    //opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(100%, 0);
    //opacity: 0;
  }
}


@media (max-width: 767px) {
  .btn {
    font-size: 14px;
    margin-top: 0;
    min-height: 40px;
    border-radius: 5px;
  }

  .input-item-wrap {
    margin-bottom: 24px;

    .input-label {
      font-size: 10px;
      line-height: initial;
    }

    .input-form {
      border-radius: 5px;
      line-height: normal;
      padding: 14px;
      font-size: 10px;
    }
  }
}
