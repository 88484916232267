@import 'src/styles/index';

.container {
  width: 619px;
  height: 534px;
  display: flex;
  flex-direction: column;
  background-color: #FAFAFA;
}

.closeWrap {
  display: flex;
  align-self: end;
}

.closeIcon {
  margin-top: 33px;
  margin-right: 33px;
  margin-bottom: 13px;
  cursor: pointer;
}

.title {
  @include set-font($size: 30px, $weight: 700, $family: 'Lexend');
  color: #262832;
  margin-left: 90px;
}

.description {
  @include set-font($size: 1rem, $weight: 400, $family: 'Inter');
  color: #445374;
  line-height: 27px;
  margin: 16px 90px 35px;
  max-width: 415px;
}

.btnContainer {
  display: flex;
  margin: 0 90px;
}

@media (max-width: 767px) {
  .container {
    width: 345px;
    height: 377px;
  }

  .closeIcon {
    width: 22px;
    height: 22px;
    margin-top: 12px;
    margin-right: 12px;
    margin-bottom: 41px;
  }

  .title {
    font-size: 16px;
    margin: 0 25px 20px;
  }

  .description {
    font-size: 10px;
    margin: 0 25px 20px;
    line-height: 17px;
  }

  .btnContainer {
    display: flex;
    margin: 0 25px;
  }
}
