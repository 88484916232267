$font-size-map: (
  0: 1rem,
  1: 1.1rem,
  //2: 1.2rem,
  2: 1.25rem,
  3: 1.3rem,
  4: 1.4rem,
  5: 1.6rem,
  6: 1.875rem,
  //6: 1.8rem,
  7: 2.2rem,
  8: 2.4rem,
  9: 4rem,
  10: 5rem,
  11: 8rem,
  12: 10rem,
);

$font-height-map: (
  //0: 1.3rem,
  0: 1.6875rem,
  1: 1.6rem,
  2: 1.8rem,
  3: 1.5rem,
  4: 2rem,
  5: 2.3rem,
  6: 2.34375rem,
  //6: 3rem,
  7: 3.4rem,
  8: 4.5rem,
  9: 4.5rem,
  10: 7rem,
);

$font-weight-map: (
  0: 400,
  1: 400,
  2: 400,
  3: 500,
  4: 500,
  5: 400,
  6: 700,
  7: 500,
  8: 500,
  9: 500,
  10: 500,
  11: 700,
);
