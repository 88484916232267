@import 'src/styles/index';

.hintContainer {
  position: absolute;
  background-color: #262832;
  bottom: 40px;
  width: 224px;
  border-radius: 16px;
}

.hintText {
  @include set-font($size: 0.75rem, $weight: 400, $family: 'Inter');
  line-height: 16px;
  color: #FFF;
  padding: 12px;
  text-align: left;
}

.hintContainer::before {
  content: "";
  position: absolute;
  left: 45%;
  bottom: -8px;
  margin-top: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #262832;
}
