@import 'src/styles/index';

.container {
  width: 775px;
  height: 927px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
}

.closeWrap {
  display: flex;
  align-self: end;
}

.closeIcon {
  margin-top: 33px;
  margin-right: 33px;
  margin-bottom: 13px;
  cursor: pointer;
}

.title {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  text-align: center;
  color: #262832;
  margin-bottom: 4rem;
}

.content {
  width: 440px;
  @include set-font($size: 1rem, $weight: 500, $family: 'Inter');
  color: #445374;
  height: 100%;
}

.enterAmount {
  margin-left: .5rem;
  margin-bottom: 8px;
}

.tierRateWrap {
  background-color: rgba(154, 149, 206, 0.1);
  height: 128px;
  border-radius: 10px;
  margin-top: 36px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #445374;
  padding-left: 34px;
}

.tierRateApy {
  @include set-font($size: 25px, $weight: 600, $family: 'Inter');
}

.tierRateTitle {
  margin-bottom: 21px;
  @include set-font($size: 11px, $weight: 400, $family: 'Inter');
}

.errorBlock {
  height: 180px;
  color: red;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.attentionText {
  @include set-font($size: 0.75rem, $weight: 400, $family: 'Inter');
  background-color: rgba(255, 149, 0, 0.1);
  padding: 24px;
  border-radius: 10px;
  color: #FF9500;
  display: flex;
  align-items: center;
  margin-top: 42px;
  margin-bottom: 24px;
}

.attentionIcon {
  height: 24px;
  margin-right: 24px;
}

.completedText {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  max-width: 423px;
  text-align: center;
}

.assetIcon {
  width: 46px;
  height: 46px;
  margin-right: 16px;
}

.dropDownSelectedText {
  @include set-font($size: 1rem, $weight: 600, $family: 'Lexend');
  color: #445374;
  padding-left: 6px;
}

.networksDropdownItem {
  border-color: rgba(154, 149, 206, 0.1);
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #FFF;
  }
}

.backBtn {
  background-color: #FAFAFA;
  margin-top: 12px;
  color: black;

  &:hover {
    background-color: #FFFFFF;
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
  }

  .closeWrap {
    display: none;
  }

  .completedText {
    font-size: 16px;
  }

  .title {
    font-size: 16px;
    margin: 64px 0 29px;
  }

  .content {
    width: auto;
    margin: 0 32px;
    position: relative;
  }

  .mobileForm {
    width: 100%;
  }

  .enterAmount {
    font-size: 12px;
  }

  .assetIcon {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }

  .tierRateWrap {
    margin-top: 0;
    padding-left: 21px;
    height: 100px;
  }

  .tierRateTitle {
    font-size: 12px;
    margin-bottom: 12px;
  }

  .tierRateApy {
    font-size: 18px;
  }

  .errorBlock {
    height: 34px;
    margin-top: 23px;
  }

  .attentionText {
    padding: 18px;
    font-size: 8px;
    margin-top: 46px;
    margin-bottom: 12px;
  }

  .attentionIcon {
    height: 18px;
    margin-right: 18px;
  }

  .backBtn {
    background-color: #FFFFFF;
    margin-top: 12px;
    color: black;

    &:hover {
      background-color: #FAFAFA;
    }
  }

  .dropDownSelectedText {
    font-size: 14px;
  }
}
