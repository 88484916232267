@import 'src/styles/index';

.container {
  width: 775px;
  height: 845px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
}

.closeWrap {
  display: flex;
  align-self: end;
}

.closeIcon {
  margin-top: 33px;
  margin-right: 33px;
  margin-bottom: 13px;
  cursor: pointer;
}

.title {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  text-align: center;
  color: #262832;
  margin-bottom: 1rem;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 440px;
}

.formMobileContent {}

.description {
  @include set-font($size: 1rem, $weight: 400, $family: 'Inter');
  color: #445374;
  text-align: center;
  line-height: 27px;
  margin-bottom: 54px;
}

.completeIconWrap {
  height: 159px;
  margin-bottom: 79px;
  margin-top: 160px;
}

@media (max-width: 767px) {
  .container {
    width: auto;
    height: 100%;
    background-color: #FFFFFF;
  }

  .closeWrap {
    display: none;
  }

  .title {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .titleMainMobile {
    margin-top: 62px;
    margin-bottom: 18px;
  }

  .description {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 49px;
  }

  .descriptionStep1 {
    flex-grow: 1;
  }

  .content {
    width: auto;
    padding: 0 32px;
    justify-content: inherit;
  }

  .formMobileContent {
    width: 100%;
    height: 100%;
  }

  .completeIconWrap {
    height: auto;
    margin-bottom: 0;
    margin-top: 180px;
  }
}
