@import 'src/styles';

.modalMobileContainer {
  background-color: #F5F6F6;
  min-width: 100%;
}

.headerTitle {
  display: flex;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: #F5F6F6;
  min-height: 32px;
  padding: 64px 16px 10px;
  justify-content: space-between;
  align-items: center;
  @include set-font($size: 24px, $weight: 700, $family: 'Lexend');
  color: #262832;
  z-index: 1;
}

.contentContainerWrap {
  width: 100vw;
  min-height: calc(100% - 106px);;
  display: flex;
}

.contentContainer {
  background-color: #FFFFFF;
  margin: 16px 15px 64px;
  flex-grow: 1;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 767px) {}
