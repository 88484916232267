@import 'src/styles';

.main {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: 100vh;
  position: relative;
}

.mainNavPanel {
  background-color: #FAFAFA;
  flex: 0 0 auto;
  min-width: 312px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //overflow: scroll;
}

.closeNav {
  display: none;
  cursor: pointer;
}

.navLinksContainer {
  display: flex;
  flex-direction: column;
  padding-left: 36px;

  &:not(.title):last-child {
    margin-bottom: 15px;
  }
}

.udLink {
  background-color: rgba(154, 149, 206, 0.1);
  width: 240px;
  height: 240px;
  border-radius: 10px;
  margin-bottom: 64px;
}

.udLinkContent {
  padding: 24px;
}

.udLinkTitle {
  @include set-font($size: 1.125rem, $weight: 700, $family: 'Lexend');
  line-height: 24px;
  color: #262832;
  margin-bottom: 8px;
}

.udLinkDescription {
  @include set-font($size: 0.875rem, $weight: 400, $family: 'Inter');
  line-height: 24px;
  color: #445374;
}

.udLinkBtn {
  @include set-font($size: 0.875rem, $weight: 600, $family: 'Lexend');
  line-height: 24px;
  color: #FFF;
  background-color: var(--mainBlue);
  padding: 8px 0px;
  border-radius: 10px;
  margin-top: 24px;
  cursor: pointer;
  text-align: center;
}

.mainTopSection {
  width: 100%;
  flex: 1;
  background-color: blue;
  max-height: 275px;
}

.mainContentSection {
  flex: 1;
  background-color: green;
}

.bbLogo {
  margin: 4rem 1rem;
  max-width: 9.375rem;
}

@media (max-width: 767px) {
  .main {
    height: auto;
    min-height: 100vh;
    overflow: unset;
  }

  .deActivePanel {
    display: none;
  }

  .mainNavPanel {
    position: fixed;
    width: 100%;
    height: 100vh;
    align-items: center;
    overflow-x: hidden;
    z-index: 2;
  }

  .navLinksContainer {
    width: 80%;
    padding-left: 0;
    align-items: center;
  }

  .closeNav {
    display: block;
    margin-top: 30px;
    margin-bottom: -40px;
    width: 100%;
    text-align: right;
    margin-right: 17px;
  }

  .mobSecondContainer {
    height: 70%;
  }

  .udLink {
    margin-bottom: 30px;
    margin-top: 30px;
    order: 2;
    width: auto;
    height: auto;
  }

  .udLinkTitle {
    font-size: 12px;
  }

  .udLinkDescription {
    font-size: 10px;
    line-height: 14px;
    margin: 0 10px;
  }

  .udLinkContent {
    padding: 17px;
    text-align: center;
  }
}
