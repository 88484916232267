@import 'src/styles/index';

.main {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  min-height: 100vh;
  //background-image: url('../../../assets/images/bg_kaizen.png');
  background-size: cover;
}

.rightModule {
  @include flex(center, center, column);
  position: relative;
  width: 51%;
  background-color: #FFFFFF;
}

.formWrap {
  @include flex(stretch, center, column);
  max-width: 440px;
  width: 95%;
}

.checkBoxWrap {
  margin-bottom: 4rem;
}

/* Customize Check Box + the label (the container) */
.container {
  @include flex(center, start, row);
  @include set-font($mapKey: 0, $weight: 500, $family: 'Inter');
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 3rem;
  color: #445374;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 6px;
  background-color: rgba(154, 149, 206, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

///* On mouse-over, add a grey background color */
//.container:hover input ~ .checkmark {
//  background-color: #ccc;
//}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  //background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  width: 1rem;
  height: 1rem;
  border-radius: 4px;
  background-color: $link-color;
}

.backBtnMobile {
  height: 60px;
}

.sumsub {
  width: 100%;
  margin-top: 100px;
}

.backBtn {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 40px;
  margin-top: 40px;
  background-color: rgba(154, 149, 206, 0.1);
  border-radius: 10px;
  padding: 12px;
  display: flex;
  cursor: pointer;

  .btnIcon {
    margin-right: 0.625rem;
    display: flex;
    align-self: center;
    width: 15px;
    height: 15px;
  }

  .btnText {
    @include set-font($mapKey: 0, $weight: 600, $family: 'Inter');
    color: $link-color;
  }
}

.iconMessage {
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
}

.hintPassword {
  position: absolute;
  min-width: 110px;
  background-color: #262832;
  top: 0;
  right: -136px;
  border-radius: 10px;
  color: white;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.hintPassword::before {
  content: "";
  position: absolute;
  top: 30%;
  left: -10px;
  margin-top: -10px; /* половина высоты треугольника */
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #262832; /* цвет треугольника */
}

.hidePasswordHint {
  opacity: 0;
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 1s ease-in forwards;
}

.fadeOut {
  opacity: 1;
  animation: fadeOut 1s ease-out forwards;
}

.heightOut {
  height: 0;
  width: 0;
  overflow: hidden;
  animation: heightOut 1s ease-out forwards;
}

@keyframes heightOut {
  from {
    height: 100%;
    width: 100%;
  }
  to {
    height: 0;
    width: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 767px) {
  /* Стили, применяемые для устройств с шириной экрана до 767px */

  .rightModule {
    width: 100%;
  }

  .backBtn {
    margin-top: 10px;
    margin-left: 10px;
  }

  .sumsub {
    margin-top: 20px;
  }

  .hintPassword {
    display: none;
  }
}
