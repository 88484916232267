@import 'src/styles/index';

.container {
  width: 775px;
  height: 845px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
}

.closeWrap {
  display: flex;
  align-self: end;
}

.completedText {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  max-width: 423px;
  text-align: center;
  margin-top: 30px;
  align-self: center;
}

.closeBtn {
  display: none;
}

.closeIcon {
  margin-top: 33px;
  margin-right: 33px;
  margin-bottom: 13px;
  cursor: pointer;
}

.dropDownSelectedText {
  @include set-font($size: 1rem, $weight: 600, $family: 'Lexend');
  color: #445374;
  //padding-left: 19px;
}

.secondBlock {
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  display: flex;
}

.snackWrap {
  margin: 24px;
  background-color: #FFE5E5;
  padding: 30px;
  border-radius: 10px;
  max-width: 636px;
}

.title {
  @include set-font($size: 0.875rem, $weight: 600, $family: 'Lexend');
  color: green;
}

.networksDropdownMain {
  position: relative;
  height: 58px;
  border-radius: 10px;
  border: rgba(154, 149, 206, 0.1) solid 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #FFF;//var(--inputBackGround);
}

.networksDropdownTriangle {
  transform: rotate(-90deg);
  width: 17px;
  margin-right: 12px;
}

.networksDropdownContainer {
  position: absolute;
  background-color: #fff;
  width: 434px;
  border-radius: 10px;
  border-width: 1px;
  border-color: var(--mainBlue);
  border-style: solid;
  margin-top: 8px;
  z-index: 1;
  overflow: hidden;
  max-height: 369px;
  overflow-y: auto;
}

.networksDropdownContainerTrade {
  width: 99%;
  max-height: 306px;
  overflow: auto;
}

.networksDropdownItem {
  border-color: rgba(154, 149, 206, 0.1);
  border-style: solid;
  height: 58px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: var(--dropDownListBg);

  &:hover {
    background-color: #FFF;
  }
}

.qrWrap {
  background-color: var(--amountInputBackground);//rgba(154, 149, 206, 0.1);
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 32px;
}

.qrContent {
  background-color: #FFF;
  padding: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.qrText {
  height: 42px;
  display: flex;
  background-color: #FFFFFF;
  align-items: center;
  border-radius: 10px;
  margin-top: 16px;
  justify-content: space-between;
}

.addressText {
  @include set-font($size: 0.75rem, $weight: 500, $family: 'Inter');
  color: #445374;
  margin-left: 12px;
  overflow: hidden;
  margin-right: 12px;
}

.note {
  @include set-font($size: 0.625rem, $weight: 500, $family: 'Inter');
  color: #445374;
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.assetIcon {
  width: 46px;
  height: 46px;
  margin-right: 16px;
  border-radius: 5px;
}

.requestErrorWrap {
  height: 78px;
  background-color: rgba(255, 0, 0, 0.1);
  margin-top: 24px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.requestErrorIcon {
  height: 24px;
  margin: 0 24px;
}

.requestErrorInfo {
  margin-right: 24px;
  @include set-font($size: 1rem, $weight: 500, $family: 'Inter');
}

.completeIconBlurWrap {
  position: relative;
  width: 221px;
  height: 221px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.completeIconBlur {
  boxShadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
  width: 141px;
  height: 141px;
  border-radius: 100%;
  z-index: 1;
}

.blurEffect {
  position: absolute;
  background-color: var(--mainBlue);
  width: 221px;
  height: 221px;
  border-radius: 100%;
  filter: blur(150px);
  opacity: 0.8;
}

@media (max-width: 767px) {
  .container {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
  }

  .closeWrap {
    display: none;
  }

  .completedText {
    font-size: 16px;
    margin-top: 37px;
    margin-bottom: 6px;
  }

  .closeBtn {
    display: flex;
    padding: 32px;
  }

  .btnMobile {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .networksDropdownMain {
    height: 44px;
    border-radius: 5px;
  }

  .dropDownSelectedText {
    font-size: 14px;
    //padding-left: 9px;
  }

  .networksDropdownContainer {
    width: 99%;
    border-radius: 5px;
  }

  .networksDropdownContainerTrade {
    width: 99%;
  }

  .networksDropdownTriangle {
    margin-left: 3px;
    margin-right: 3px;
  }

  .networksDropdownItem {
    height: 44px;
  }

  .requestErrorWrap {
    //max-width: calc(100vw - 94px);
    overflow: hidden;
  }

  .requestErrorIcon {
    margin: 0 12px;
  }

  .requestErrorInfo {
    margin-right: 12px;
    word-wrap: break-word;
    max-width: calc(100% - 60px);
    text-align: center;
    font-size: 12px;
  }

  .completeIconBlurWrap {
    width: 166px;
    height: 166px;
  }

  .completeIconBlur {
    width: 106px;
    height: 106px;
  }

  .blurEffect {
    width: 166px;
    height: 166px;
  }

  .assetIcon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }

  .qrWrap {
    padding: 13px;
    //width: 180px;
  }

  .qrContent {
    padding: 13px;
  }
}
