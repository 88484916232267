@import 'src/styles/index';

.titleWrap {
  margin-bottom: 4rem;
}

.title {
  @include set-font($mapKey: 6, $weight: 700, $family: 'Lexend');
}

.description {
  @include set-font($mapKey: 0, $weight: 400, $family: 'Inter');
  color: $text-color-2;
  margin-top: .5rem;
}
