@import 'src/styles/index';

.container {
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 0;
  left: 0;
  //left: 100%;
  //width: 100%;
  //transition: .5s;
  display: flex;
  overflow-x: hidden;
  background-color: rgba(38, 40, 50, 0.3);
  justify-content: center;
  align-items: center;
}

.openModal {
  width: 100%;
  //left: 0;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.desktopRightModal {
  justify-content: end;
  align-items: flex-start;
}

.contentRightModal {
  min-height: 100%;
  background: #FAFAFA;
}

@media (max-width: 767px) {
  .container {
    left: 100%;
    width: 100%;
    transition: .5s;
    height: calc(100% - 182px);
    padding-top: 122px;
    padding-bottom: 60px;
  }
  .content {
    width: calc(100% - 30px);
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: end;
  }

  .openModal {
    left: 0;
  }
}
