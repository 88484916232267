@import 'src/styles';

.portfolioWrap {
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 1347px;
  max-width: 2700px;
}

.tableContainer {
  flex: 1 1 auto;
  overflow: auto;
  padding: 0 24px 16px;
  background-color: rgba(163, 163, 163, 0.05);
  border-radius: 10px;
  margin-left: 24px;
  margin-right: 24px;
  background-color: #FFFFFF;
}

.tableTitleRow {
  display: flex;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-color: #F3F3F4;
  border-bottom-style: solid;
  background-color: #fff;
  margin: 0 24px;
  padding: 24px;
}

.tableTitle {
  @include set-font($size: 1rem, $weight: 500, $family: 'Lexend');
  color: #445374;
  margin-bottom: 12px;
}

.tableHideZeroWrap {
  display: flex;
  align-items: center;
}

.tableHideZero {
  @include set-font($size: 0.75rem, $weight: 500, $family: 'Lexend');
  color: #99A1B2;
  margin-right: 12px;
}

.table {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #445374;
  @include set-font($size: 0.875rem, $weight: 600, $family: 'Inter');

  &:hover {
    background-color: rgba(154, 149, 206, 0.08);
    border-radius: 5px;
    cursor: pointer;
  }
}

.headerWrap {
  background-color: #fff;
  margin: 0 24px;
  padding: 16px 24px;
}

.header {
  @include set-font($size: 0.75rem, $weight: 500, $family: 'Inter');
  color: #99A1B2;

  &:hover {
    background-color: #fff;
    cursor: auto;
  }
}

.cell {
  flex: 1;
  flex-basis: 0;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.assetIcon {
  width: 46px;
  height: 46px;
  border-radius: 5px;
}

.cell1 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 250px;
}

.cell2 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 410px;
}

.assetName {
  margin-left: 21px;
  align-self: center;
  display: flex;
}

.assetChangeRateWrap {
  background-color: rgba(43, 154, 24, 0.2);
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 1rem;
}

.actionBtn {
  width: 114px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ECECED;
  border-radius: 7px;
  margin-right: 8px;
  cursor: pointer;
  background-color: #FFFFFF;

  &:hover {
    background-color: #F8F8F8;
  }
}

.assetPrice {
  display: flex;
  align-items: center;
}

.view {
  color: var(--mainBlue);
  position: relative;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.assetSymbol {
  color: rgba(68, 83, 116, 0.5);
  margin-left: 12px;
}

.mobileAmount {
  display: none;
}

@media (max-width: 767px) {
  .portfolioWrap {
    min-width: 100%;
    max-width: 100%;
  }

  .tableTitleRow {
    margin: 0 12px;
    background-color: #FAFAFA;
    padding: 12px;
    border-bottom-style: none;
  }

  .tableTitle {
    margin-bottom: 0;
  }

  .tableHideZero {
    @include set-font($size: 10px, $weight: 500, $family: 'Inter');
  }

  .headerWrap {
    display: none;
  }

  .tableContainer {
    margin: 0 15px;
    padding: 0;
  }

  .assetName {
    margin-left: 8px;
    align-self: center;
    display: flex;
    flex-direction: column;
    text-align: start;
    color: rgba(68, 83, 116, 0.5);
  }

  .assetSymbol {
    color: #445374;
    margin-bottom: 3px;
  }

  .assetTitle {
    order: 2;
    font-size: 10px;
  }

  .assetSymbol {
    margin-left: 0;
  }

  .hideCell {
    display: none;
  }

  .mobileAmount {
    display: block;
    @include set-font($size: 10px, $weight: 600, $family: 'Inter');
    //background-color: red;
    text-align: right;
    margin-right: 16px;
  }

  .mobAvailableBalance {
    font-size: 14px;
    margin-bottom: 3px;
  }

  .assetIcon {
    width: 31px;
    height: 31px;
  }
}
