@import 'src/styles/index';

.passwordHintBlock {
  display: flex;
  align-items: center;
  margin: 5px
}

.passwordHintContainer {
  width: 3px;
  height: 3px;
  border-radius: 100%;
  margin-right: 5px;
  background-color: #FFFFFF;
}

.text {
  color: #FFFFFF;
  font-size: 10px;
}

.textSuccess {
  color: #2B9A18;
}

@media (max-width: 767px) {
  .text {
    font-size: 8px;
    color: #445374;
  }

  .textSuccess {
    color: #2B9A18;
  }

  .passwordHintContainer {
    background-color: #445374;
  }
}
