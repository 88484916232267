@import 'src/styles/index';

.container {
  width: 792px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
  position: relative;
}

.main {
  min-height: 700px;
  width: 440px;
  display: flex;
  flex-direction: column;
}

.navBlock {
  min-height: 34px;
  display: flex;
  justify-content: center;
}

.navBlock div:nth-child(2) {
  margin: 0 12px;
}

.navItem {
  @include set-font($size: 0.875rem, $weight: 600, $family: 'Inter');
  width: 123px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: rgba(17, 17, 17, 0.1);
  color: #111111;
  cursor: pointer;
}

.navItemActive {
  background-color: #111111;
  color: #FFFFFF;
}

.footer {
  max-height: 208px;
  min-height: 100px;
  flex-grow: 1;
}

.header {
  max-height: 208px;
  min-height: 108px;
  flex-grow: 1;
}

.title {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  text-align: center;
  color: #262832;
  margin-top: 54px;
  margin-bottom: 17px;
}

.description {
  @include set-font($size: 1rem, $weight: 400, $family: 'Inter');
  text-align: center;
  color: #445374;
  margin-bottom: 50px;
}

.inputBlock {
  display: flex;
  background-color: var(--amountInputBackground); //#E7EAF9;//rgba(154, 149, 206, 0.1);
  height: 60px;
  max-width: 228px;
  border-radius: 10px;
  overflow: hidden;
  //background-color: red;
  outline: #fff solid 1px;
}

.assetName {
  @include set-font($size: 1.125rem, $weight: 500, $family: 'Inter');
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.currencyBlock {
  @include set-font($size: 0.625rem, $weight: 400, $family: 'Inter');
  color: rgba(68, 83, 116, 0.5);
  align-self: end;
  //margin-top: 7px;
  margin-right: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
}

.input {
  @include set-font($size: 1.125rem, $weight: 500, $family: 'Inter');
  border: 0;
  text-align: right;
  background-color: rgba(154, 149, 206, 0);
  color: #445374;
  //margin-right: 16px;
  width: 90%;

  ::placeholder {
    color: $text-color-2;
    opacity: .5;
  }

  &:focus-visible {
    outline: 0;
  }
}

.enterAmount {
  @include set-font($size: 1rem, $weight: 500, $family: 'Inter');
  margin-left: .5rem;
  margin-bottom: .3rem;
  color: #445374;
}

.focusAmount {
  outline-color: var(--mainBlue);
}

.balance {
  @include set-font($size: 0.75rem, $weight: 400, $family: 'Inter');
  margin-left: .5rem;
  color: #445374;
  //margin-top: 15px;
}

.max {
  @include set-font($size: 0.6875rem, $weight: 400, $family: 'Inter');
  color: #445374;
  width: 55px;
  height: 28px;
  background-color: var(--amountInputBackground); //#E7EAF9;//rgba(154, 149, 206, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 16px;
}

.conversionRate {
  @include set-font($size: 0.6875rem, $weight: 400, $family: 'Inter');
  margin-left: .5rem;
  color: #445374;
}

.titleAmount {
  @include set-font($size: 1.5rem, $weight: 600, $family: 'Lexend');
  margin-left: .5rem;
  color: #262832;
}

.completedText {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  max-width: 423px;
  text-align: center;
}

.totalAmount {
  @include set-font($size: 1.5rem, $weight: 600, $family: 'Inter');
  color: #445374;
  margin-left: .5rem;
}

.enoughBalanceEur {
  @include set-font($size: 0.625rem, $weight: 500, $family: 'Inter');
  color: #FF0000;
  margin-left: .5rem;
  margin-top: 9px;
  height: 12px;
}

.backBtn {
  background-color: #FAFAFA;
  margin-top: 12px;
  color: black;

  &:hover {
    background-color: #FFFFFF;
  }
}

@media (max-width: 767px) {

  .container {
    width: auto;
  }

  .header {
    display: none;
  }

  .title {
    margin-bottom: 1rem;
    font-size: 16px;
  }

  .content {
    width: 95%;
  }

  .main {
    width: auto;
    max-width: 100%;
    min-height: auto;
  }

  .navBlock {
    width: 95%;
    margin: 30px auto 0;
  }

  .formWrap {
    padding: 12px;
  }

  .inputBlock {
    border-radius: 5px;
    height: 46px;
  }

  .navItem {
    width: 100px;
  }

  .description {
    font-size: 10px;
  }

  .enterAmount {
    font-size: 12px;
  }

  .completedText {
    font-size: 16px;
  }

  .backBtn {
    background-color: #FFFFFF;
    margin-top: 12px;
    color: black;

    &:hover {
      background-color: #FAFAFA;
    }
  }
}
