@import 'src/styles';

.headerCards {
  margin-right: 24px;
  flex: 0 0 273px;
}

@media (max-width: 767px) {
  .headerCards {
    margin-left: 0;
    margin-right: 0;
    flex-basis: auto;
  }
}
