@import 'src/styles';

.infoCards {
  display: flex;
  margin-left: 24px;
}

.infoCardsItemWrap {
  margin-right: 24px;
  flex-grow: 1;
}


@media (max-width: 767px) {
  .infoCards {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 15px;
  }

  .infoCardsItemWrap {
    margin-right: 0;
    flex-grow: 0;
    width: 48%;
    margin-bottom: 12px;
  }

  .hideBlock {
    display: none;
  }
}
