@import 'src/styles';

.settingsWrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 24px;
  margin-right: 24px;
}

.settingsItemsWrap {
  display: flex;
  flex-wrap: wrap;
}

.settingsItem {
  height: 196px;
  width: 504px;
  background-color: #FFFFFF;
  border-radius: 10px;
  margin-right: 16px;
  margin-bottom: 24px;
  display: flex;
}

.settingsItemContent {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.settingsItemTitle {
  @include set-font($size: 1rem, $weight: 500, $family: 'Lexend');
  color: #262832;
}

.settingsItemDescription {
  @include set-font($size: 0.875rem, $weight: 400, $family: 'Inter');
  line-height: 24px;
  color: #445374;
}

.currencySwitchBlock {
  height: 50px;
  background-color: rgba(154, 149, 206, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.currencyBtn {
  @include set-font($size: 0.875rem, $weight: 600, $family: 'Inter');
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 46px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 10px;
  cursor: pointer;
  color: #99A1B2;
}

.currencyBtnActive {
  background-color: #FFFFFF;
  color: #445374;
}

.twoFaBtn {
  @include set-font($size: 0.875rem, $weight: 600, $family: 'Inter');
  background-color: var(--mainBlue);
  color: #FFFFFF;
  height: 48px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.recommended {
  color: #2B9A18;
  background-color: rgba(43, 154, 24, 0.2);
  height: 25px;
  border-radius: 20px;
  padding: 3px 12px;
  @include set-font($size: 0.875rem, $weight: 500, $family: 'Lexend');
  display: flex;
  justify-content: center;
  align-items: center;
}

.twoFaTitleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activeBlock {
  height: 48px;
  display: flex;
  align-items: center;
}

.activeBtn {
  background-color: rgba(43, 154, 24, 0.2);
  @include set-font($size: 0.875rem, $weight: 500, $family: 'Lexend');
  color: #2B9A18;
  padding: 14px 50px;
  border-radius: 100px;
}

.deactivateBtn {
  @include set-font($size: 0.875rem, $weight: 600, $family: 'Inter');
  margin-left: 24px;
  cursor: pointer;
  color: var(--mainBlue);
}

.disableBtn {
  @include set-font($size: 0.875rem, $weight: 600, $family: 'Inter');
  background-color: var(--disableBtn);
  color: var(--mainBlue);
  height: 48px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.copyBtn {
  background-color: var(--copyBtn)
}

@media (max-width: 767px) {
  .settingsWrap {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .settingsItem {
    width: auto;
    min-height: 140px;
    height: auto;
    margin-left: 15px;
    margin-right: 15px;
  }

  .settingsItemTitle {
    font-size: 12px;
  }

  .settingsItemDescription {
    font-size: 10px;
    line-height: 17px;
    padding: 8px 0;
  }

  .settingsItemContent {
    padding: 17px;
  }

  .recommended {
    font-size: 7px;
    height: 12px;
  }

  .currencyBtn {
    font-size: 10px;
  }

  .twoFaBtn {
    font-size: 10px;
  }

  .disableBtn {
    font-size: 10px;
    color: #445374;
  }

  .activeBtn {
    font-size: 10px;
    padding: 10px 40px;
  }

  .deactivateBtn {
    font-size: 10px;
  }
}
