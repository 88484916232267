@import 'src/styles/index';

.container {
  width: 775px;
  height: 845px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
}

.closeWrap {
  display: flex;
  align-self: end;
}

.closeIcon {
  margin-top: 33px;
  margin-right: 33px;
  margin-bottom: 13px;
  cursor: pointer;
}

.title {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  text-align: center;
  color: #262832;
  margin-bottom: 4rem;
}

.description {
  @include set-font($size: 1rem, $weight: 400, $family: 'Inter');
  color: #445374;
  text-align: center;
  line-height: 27px;
  margin-bottom: 54px;
}

.content {
  width: 436px;
  @include set-font($size: 1rem, $weight: 500, $family: 'Inter');
  color: #445374;
}

.enterAmount {
  margin-left: .5rem;
  margin-bottom: .3rem;
}

.withdrawInput {
  background-color: var(--amountInputBackground);
  position: relative;
  height: 58px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #FAFAFA;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.focusAmount {
  border-color: var(--mainBlue);
}

.assetIcon {
  width: 46px;
  border-radius: 10px;
  margin-left: 6px;
  margin-right: 12px;
}

.assetNameWrap {
  display: flex;
  align-items: center;
}

.assetName {
  @include set-font($size: 1.125rem, $weight: 500, $family: 'Inter');
}

.inputBlock {
  margin-right: 14px;
  display: flex;
  flex-direction: column;
}

.input {
  @include set-font($size: 1.125rem, $weight: 500, $family: 'Inter');
  border: 0;
  text-align: right;
  background-color: rgba(154, 149, 206, 0);
  color: #445374;
  width: 100%;

  ::placeholder {
    color: $text-color-2;
    opacity: .5;
  }

  &:focus-visible {
    outline: 0;
  }
}

.currencyBlock {
  @include set-font($size: 0.625rem, $weight: 400, $family: 'Inter');
  color: rgba(68, 83, 116, 0.5);
  align-self: end;
  margin-top: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.balanceWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 25px;
}

.balance {
  margin-left: 12px;
  @include set-font($size: 0.6875rem, $weight: 400, $family: 'Inter');
}

.maxBtn {
  @include set-font($size: 0.6875rem, $weight: 400, $family: 'Inter');
  background-color: var(--mainBlue);
  padding: 7px 15px;
  border-radius: 10px;
  color: #FFFFFF;
  cursor: pointer;
}

.note {
  @include set-font($size: 0.625rem, $weight: 500, $family: 'Inter');
  color: #445374;
  margin-top: 61px;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.summaryBlockTitle {
  @include set-font($size: 0.75rem, $weight: 500, $family: 'Inter');
  color: #445374;
  margin-bottom: 1rem;
}

.cryptoAmount {
  @include set-font($size: 1.5rem, $weight: 600, $family: 'Inter');
  color: #445374;
  margin-bottom: 6px;
}

.currencyAmount {
  @include set-font($size: 1rem, $weight: 600, $family: 'Inter');
  color: #445374;
  margin-bottom: 24px;
}

.conversion {
  @include set-font($size: 0.75rem, $weight: 400, $family: 'Inter');
  color: #445374;
  margin-bottom: 6px;
}

.divider {
  border-bottom: 1px solid rgba(38, 40, 50, 0.1);
  margin: 24px 0;
}

.completedText {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  max-width: 423px;
  text-align: center;
}

.cryptoAddress {
  @include set-font($size: 1rem, $weight: 600, $family: 'Inter');
  color: #445374;
  overflow: hidden;
}

.bankName {
  margin-left: 20px;
}

.backBtn {
  background-color: #FAFAFA;
  margin-top: 12px;
  color: black;

  &:hover {
    background-color: #FFFFFF;
  }
}

@media (max-width: 767px) {

  .container {
    width: auto;
    padding: 0 20px;
    height: 100%;
  }

  .closeWrap {
    display: none;
  }

  .title {
    margin-bottom: 29px;
    margin-top: 64px;
    font-size: 16px;
  }

  .content {
    width: 95%;
  }

  .enterAmount {
    font-size: 12px;
    margin-left: 8px;
    margin-bottom: 8px;
  }

  .assetName {
    font-size: 14px;
  }

  .input {
    font-size: 14px;
  }

  .assetIcon {
    width: 30px;
  }

  .withdrawInput {
    height: 44px;
    border-radius: 5px;
  }

  .balanceWrap {
    margin-top: 26px;
    margin-bottom: 20px;
  }

  .balance {
    margin-left: 8px;
  }

  .cryptoAddress {
    font-size: 12px;
  }

  .backBtn {
    background-color: #FFFFFF;
    margin-top: 12px;
    color: black;

    &:hover {
      background-color: #FAFAFA;
    }
  }
}
