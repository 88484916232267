@import 'src/styles/index';

.header {
  max-height: 208px;
  min-height: 108px;
  flex-grow: 1;
}

.transfer {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  color: #445374;
  text-align: center;
}

.transferDescription {
  @include set-font($size: 1rem, $weight: 400, $family: 'Inter');
  color: #445374;
  margin-top: 16px;
  text-align: center;
  margin-bottom: 127px;
  line-height: 27px;
}

.itemWrap {
  @include set-font($size: 1rem, $weight: 500, $family: 'Inter');
  color: #445374;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.copyString {
  display: flex;
  @include set-font($size: 1.125rem, $weight: 600, $family: 'Lexend');
  color: #262832;
  justify-content: space-between;
  margin-top: 5px;
  cursor: pointer;
}

.content {
  min-width: 792px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentWrap {
  padding: 24px;
  width: 482px;
}

@media (max-width: 767px) {

  .content {
    min-width: auto;
  }

  .contentWrap {
    padding: 0 31px;
    width: auto;
  }

  .header {
    display: none;
  }

  .transfer {
    font-size: 16px;
    margin: 65px 0 12px;
  }

  .transferDescription {
    font-size: 10px;
    line-height: 17px;
    margin-bottom: 39px;
  }

  .itemWrap {
    font-size: 10px;
  }

  .copyString {
    font-size: 12px;
    align-items: center;
  }
}
