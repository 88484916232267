@import 'src/styles/index';

.container {
  width: 792px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
}

.main {
  min-height: 700px;
  width: 490px;
  display: flex;
  flex-direction: column;
}

.footer {
  max-height: 208px;
  min-height: 100px;
  flex-grow: 1;
}

.header {
  max-height: 208px;
  min-height: 108px;
  flex-grow: 1;
}

.title {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  text-align: center;
  color: #262832;
  margin-top: 36px;
  margin-bottom: 64px;
}

@media (max-width: 767px) {
  .container {
    width: auto;
  }

  .header {
    display: none;
  }

  .main {
    width: auto;
  }

  .footer {
    display: none;
  }

  .title {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: start;
    font-weight: 500;
    border-bottom: 1px solid #F3F3F4;
    padding: 25px 17px;
    color: #445374;
  }
}
