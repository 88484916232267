@import 'src/styles/index';

.container {
  width: 775px;
  height: 845px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
}

.closeWrap {
  display: flex;
  align-self: end;
}

.closeIcon {
  margin-top: 33px;
  margin-right: 33px;
  margin-bottom: 13px;
  cursor: pointer;
}

.title {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  text-align: center;
  color: #262832;
  margin-bottom: 4rem;
}

.content {
  width: 440px;
}

.summaryBlockTitle {
  @include set-font($size: 0.75rem, $weight: 500, $family: 'Inter');
  color: #445374;
  margin-bottom: 1rem;
}

.cryptoAmount {
  @include set-font($size: 1.5rem, $weight: 600, $family: 'Inter');
  color: #445374;
  margin-bottom: 6px;
  display: flex;
}

.currencyAmount {
  @include set-font($size: 1rem, $weight: 600, $family: 'Inter');
  color: #445374;
  margin-bottom: 24px;
}

.conversion {
  @include set-font($size: 0.75rem, $weight: 400, $family: 'Inter');
  color: #445374;
  margin-bottom: 6px;
}

.divider {
  border-bottom: 1px solid rgba(38, 40, 50, 0.1);
  margin: 24px 0;
}

.attentionText {
  @include set-font($size: 0.75rem, $weight: 400, $family: 'Inter');
  background-color: rgba(255, 149, 0, 0.1);
  padding: 24px;
  border-radius: 10px;
  color: #FF9500;
  display: flex;
  align-items: center;
  margin-top: 42px;
  margin-bottom: 24px;
}

.dot2 {
  background-color: #000;
  width: 3px;
  height: 3px;
  border-radius: 100%;
  margin: 0 12px;
  align-self: center;
}

.completedText {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  max-width: 423px;
  text-align: center;
  margin-top: 30px;
  align-self: center;
}

.btnMobileClose {
  display: none;
}

.closeBtn {
  display: none;
}

@media (max-width: 767px) {
  .container {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 10px;
  }

  .closeWrap {
    display: none;
  }

  .content {
    width: auto;
    padding: 0 32px;
  }

  .title {
    font-size: 16px;
    margin: 62px 0 31px;
  }

  .cryptoAmount {
    font-size: 18px;
  }

  .summaryBlockTitle {
    margin-bottom: 12px;
  }

  .currencyAmount {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .conversion {
    font-size: 8px;
    margin-bottom: 0;
  }

  .attentionText {
    font-size: 8px;
    padding: 18px;
  }

  .btnMobile {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .btnMobileClose {
    display: block;
    background-color: #FFFFFF;
    color: black;
    margin-top: 8px;
  }

  .completedText {
    font-size: 16px;
    margin-top: 37px;
    margin-bottom: 6px;
  }

  .closeBtn {
    display: flex;
    padding: 32px;
  }
}
