$white: #fff;
$grey-0: #f5f5f5;
$grey-1: #a0a0a0;
$grey-2: #d2d2d2;
$grey-3: #767676;
$grey-4: #e5e5e5;
$grey-5: #dadada;
$grey-6: #e2e2e2;
$grey-7: rgba(85%, 85%, 85%, 0.5);
$grey-8: #dedede;
$grey-9: #a5a7a8;
$grey-10: #d8d8d8;
$grey-11: #c4c4c4;
$grey-12: #f1eff0;
$default-scroll-bg: #eae3e0;
$dark-blue: #242a2e;
$primary-color: #f68119;
$orange-0: #fff8f2;
$orange-1: #f26522;
$orange-2: #f6831e;
$orange-3: #eb5f22;
$border-light-gray: #f6f6f6;
$text-color: #262832;
$text-color-2: #445374;
$link-color: var(--mainBlue);// #9A95CE;
$btn-hover: #918CC1;
$green-1: #00e06d;
$black: #000;

$default-button-gradient: linear-gradient(90deg, rgba(242, 98, 34, 1) 0%, rgba(246, 131, 31, 1) 100%);
$cards-border-gradient: linear-gradient(90deg, rgba(242, 97, 34, 1) 0%, rgba(230, 176, 30, 1) 100%);
$contact-gradient: linear-gradient(185deg, rgba(249, 191, 143, 1) 0%, rgba(253, 167, 140, 1) 100%);

$shadow-1: 1px 1px 30px #d8d6d6;
$shadow-2: 1px 10px 10px #ececec;
$shadow-3: 1px 8px 14px #c8c4c4;
$card-shadow: 1px 1px 14px #ececec;
$inset-shadow-box: inset 1px 1px 10px #d7d7d7;
$inset-shadow-box-green: inset 1px 1px 1px #01c953;
$inset-shadow-icon: inset 1px 1px 5px #f1f3f4;
