@import 'src/styles/index';

.header {
  max-height: 208px;
  min-height: 108px;
  flex-grow: 1;
}

.transferDescription {
  @include set-font($size: 1rem, $weight: 400, $family: 'Inter');
  color: #445374;
  margin-top: 16px;
  text-align: center;
  line-height: 27px;
}

.transferDescription2 {
  margin-bottom: 64px;
}

.enterAmount {
  @include set-font($size: 1rem, $weight: 500, $family: 'Inter');
  color: #445374;
  margin-left: .5rem;
  margin-bottom: 12px;
}

.transfer {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  color: #262832;
  text-align: center;
}

.container {
  width: 792px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
}

.bankName {
  @include set-font($size: 1rem, $weight: 600, $family: 'Lexend');
  margin-bottom: 5px;
  margin-left: 9px;
}

.bankIban {
  @include set-font($size: 12px, $weight: 400, $family: 'Lexend');
  margin-left: 9px;
}

.inputBlock {
  @include set-font($size: 18px, $weight: 500, $family: 'Inter');
  color: #445374;
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(61, 92, 245, 0.1);
  height: 62px;
}

.input {
  @include set-font($size: 1.125rem, $weight: 500, $family: 'Inter');
  border: 0;
  //text-align: right;
  background-color: rgba(154, 149, 206, 0);
  color: #445374;
  margin-left: 12px;

  ::placeholder {
    color: $text-color-2;
    opacity: .5;
  }

  &:focus-visible {
    outline: 0;
  }
}

.bankNameResult {
  @include set-font($size: 24px, $weight: 600, $family: 'Lexend');
  margin-left: .5rem;
}

.containerNotAvailable {
  padding: 24px;
  width: 482px;
}

.containerAvailable {
  flex-grow: 1;
  width: 440px;
}

.backBtn {
  background-color: #FAFAFA;
  margin-top: 12px;
  color: black;
  font-size: 14px;

  &:hover {
    background-color: #FFFFFF;
  }
}

@media (max-width: 767px) {

  .container {
    width: auto;
  }

  .containerNotAvailable {
    padding: 15px;
    width: auto;
    flex-grow: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .containerAvailable {
    width: 100%;
    padding: 0 28px;
  }

  .header {
    display: none;
  }

  .transfer {
    font-size: 16px;
    margin-top: 103px;
  }

  .transferDescription {
    font-size: 10px;
    line-height: 17px;
    margin-bottom: 36px;
  }

  .transferDescription2 {
    display: none;
  }

  .backBtn {
    background-color: #FFFFFF;
    color: black;

    &:hover {
      background-color: #FAFAFA;
    }
  }

  .enterAmount {
    font-size: 12px;
  }

  .inputBlock {
    height: 46px;
    font-size: 14px;
  }

  .bankName {
    font-size: 14px;
  }

  .input {
    font-size: 14px;
  }

  .bankNameResult {
    font-size: 14px;
  }
}
