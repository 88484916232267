@import 'src/styles/index';

.container {
  width: 775px;
  height: 927px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FAFAFA;
}

.closeWrap {
  display: flex;
  align-self: end;
}

.closeIcon {
  margin-top: 33px;
  margin-right: 33px;
  margin-bottom: 13px;
  cursor: pointer;
}

.title {
  @include set-font($size: 1.875rem, $weight: 700, $family: 'Lexend');
  text-align: center;
  color: #262832;
  margin-bottom: 4rem;
}

.content {
  width: 436px;
}

.dropDownSelectedText {
  @include set-font($size: 1rem, $weight: 600, $family: 'Lexend');
  color: #445374;
  margin-left: 12px;
}

.chooseNetwork {
  @include set-font($size: 1rem, $weight: 500, $family: 'Inter');
  color: #445374;
  margin-left: 10px;
  margin-bottom: 5px;
}

.bankName {
  margin-left: 20px;
}

@media (max-width: 767px) {

  .container {
    width: auto;
    margin: 0 10px;
    background-color: #FFFFFF;
    height: auto;
  }

  .closeWrap {
    display: none;
  }

  .title {
    margin-bottom: 29px;
    margin-top: 64px;
    font-size: 16px;
  }

  .content {
    width: 95%;
    margin-bottom: 20px;
    position: relative;
  }

  .chooseNetwork {
    font-size: 12px;
  }
}

